import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Button, Row } from 'reactstrap';

import {
  basicQueryResults,
  clearCuts,
  pdfPrint,
} from '../../../actions/results';

class BasicQueryCuts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cuts: {},
    };

    this.clearSelection = this.clearSelection.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  componentDidMount() {
    if (window.location.pathname === '/demo/query/') {
      this.selectAll();
    }
  }

  clearSelection() {
    this.setState({
      cuts: {},
    });
    this.props.clearCuts();
    // if there is change make sure PDF print is false
    this.props.pdfPrint(false);
  }

  // pieces together the cuts that are selected
  onCutSelect(cut, str) {
    // want to make sure it stays a number
    // so that it matches the server data
    // tends to convert to string if not specific
    cut = Number(cut);

    this.setState(
      ({ cuts: prevCuts }) =>
        this.state.cuts[cut] && str !== 'all'
          ? { cuts: { ...prevCuts, [cut]: undefined } }
          : { cuts: { ...prevCuts, [cut]: cut } },
      () => this.props.basicQueryResults(this.state.cuts)
    );

    // if there is change make sure PDF print is false
    this.props.pdfPrint(false);
  }

  selectAll() {
    const { country, pos_title } = this.props;
    let { query_data } = this.props;
    let data = {};

    if (query_data) {
      if (query_data[0].country && country) {
        data = _.filter(query_data, { country: country });
      } else if (query_data[0].foundationlabel && country) {
        data = _.filter(query_data, { foundationlabel: country });
      } else {
        data = query_data;
      }

      if (query_data[0].surveycode.startsWith('E_') && !query_data[0].surveycode.includes('INTL')) {
        data = _.filter(query_data, { postitle: pos_title });
      }
    }

    _.map(data, (c) => {
      this.onCutSelect(c.sortorder, 'all');
    });

    // if there is change make sure PDF print is false
    this.props.pdfPrint(false);
  }

  // These buttons will allow selecting everything, or clearing the selection
  renderAllNothingButtons() {
    let { query_data } = this.props;

    // generate the list of cuts
    if (query_data) {
      return (
        <Row>
          <Col>
            <Button
              color='primary'
              key='all'
              className='cuts-btn'
              onClick={this.selectAll}
            >
              Select All
            </Button>
          </Col>
          <Col>
            <Button
              color='danger'
              key='cancel'
              className='cuts-btn'
              onClick={this.clearSelection}
            >
              Clear All
            </Button>
          </Col>
        </Row>
      );
    }
  }

  // renders the cut multi-list, by first ordering what comes from
  // the server and then depending on the survey
  // setting up the option and value keys
  renderCutsButtons() {
    const { country, pos_title } = this.props;
    let { query_data } = this.props;

    let data = {};

    if (query_data) {
      if (query_data[0].country && country) {
        // console.log('country: ' + country);
        data = _.filter(query_data, { country: country });
      } else if (query_data[0].foundationlabel && country) {
        // console.log('foundationlabel: ' + country);
        data = _.filter(query_data, { foundationlabel: country });
      } else {
        // console.log('no country or foundationlabel');
        data = query_data;
      }

      if (query_data[0].surveycode.startsWith('E_') && !query_data[0].surveycode.includes('INTL')) {
        // console.log("posTitle: " + pos_title);
        data = _.filter(query_data, { postitle: pos_title });
        // console.log(data);
      }
    }

    // generate the list of cuts
    return (
      <Row>
        {_.map(data, (c) => {
          var cut = c.rptcutcat + ': ' + c.rptcut;
          console.log(cut);
          return (
            <Col key={c.sortorder}>
              <Button
                className={
                  this.state.cuts[c.sortorder] ? 'cuts-btn-active' : 'cuts-btn'
                }
                key={c.sortorder}
                value={c.sortorder}
                onClick={(event) =>
                  this.onCutSelect(event.target.value, 'single')
                }
              >
                {cut}
              </Button>
            </Col>
          );
        })}
      </Row>
    );
  }

  render() {
    const { query_data } = this.props;
    return (
      <div className='results-bq-cuts'>
        {this.renderCutsButtons()}
        {query_data ? <hr /> : null}
        {this.renderAllNothingButtons()}
        {query_data ? <hr /> : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    query_data: state.results.query_data,
    country: state.results.country,
    pos_title: state.results.pos_title,
  };
}

export default connect(mapStateToProps, {
  basicQueryResults,
  clearCuts,
  pdfPrint,
})(BasicQueryCuts);
