import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import BasicQueryCuts from './Cuts';
import BasicQueryResults from './QueryResults';

import {
  clearQuery,
  demoQuery,
  getQuery,
  posCount,
} from '../../../actions/results';

class BasicQueryPostionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      modal: false,
      position: null,
    };
    this.toggle = this.toggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  // this will get the initial count and list of pos_list
  componentDidMount() {
    const { search_term, pos_list } = this.props;

    const filtered_positions = this.filterPositions(search_term, pos_list);

    this.getCount(filtered_positions);
  }

  // this will permit the count of pos without interfering render
  componentDidUpdate(prevProps, prevState) {
    if (
      !document.getElementById('position-list').value &&
      !this.state.disabled
    ) {
      this.setState({
        disabled: true,
      });
    }

    const { search_term, pos_list } = this.props;

    const filtered_positions = this.filterPositions(search_term, pos_list);

    this.getCount(filtered_positions);
  }

  componentWillUnmount() {
    this.getCount(null);
  }

  // filter down the list of positions
  filterPositions(term, pos) {
    let filtered_items = _.filter(pos, (p) =>
      (p.finalcode + ' - ' + p.postitle).toLowerCase().match(term)
    );

    if (filtered_items.length === 0) {
      return filtered_items;
    } else if (filtered_items[0].country) {
      filtered_items = _.filter(filtered_items, (p) =>
        p.country.match(this.props.country)
      );
      return filtered_items;
    } else if (filtered_items[0].foundationlabel && this.props.country) {
      filtered_items = _.filter(filtered_items, (p) =>
        p.foundationlabel.match(
          this.props.country.replace('(', '\\(').replace(')', '\\)')
        )
      );
      return filtered_items;
    } else {
      return filtered_items;
    }
  }

  // count how many positions are in the list
  getCount(n) {
    let length = n ? n.length : 0;
    this.props.posCount(length);
  }

  // refresh the browser for a couple reason:
  // 1) to clear out the previous queries data
  // 2) to clear out the blobs that are generated from PDFs
  //    which inevitably leads to a crash in the browser
  handleClose() {
    window.location = '/results#query';
    window.location.reload(true);
  }

  // handling when the user selects an option from the drop down
  onPositionSelect(event) {
    const { country, survey_id, survey_code } = this.props;

    this.props.clearQuery();
    if (window.location.pathname === '/demo/query/') {
      this.props.demoQuery(parseInt(event));
    } else {
      this.props.getQuery(event, survey_id, survey_code, country);
    }

    // allow user to submit position data after selected
    let disabled;
    if (event) {
      disabled = false;
    } else {
      disabled = true;
    }

    this.setState({
      disabled: disabled,
      position: event,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      disabled: true,
    });
  }

  // renders the options and also filters down the results
  renderPositionsOptions(pos) {
    // generate the <option> list
    return _.map(pos, (p) => {
      let option = '';
      if (p.organizationalunit && !this.props.survey_code.startsWith('E_')) {
        option =
          p.finalcode + ' - ' + p.postitle + ' - ' + p.organizationalunit;
      } else {
        option = p.finalcode + ' - ' + p.postitle;
      }
      // console.log(option);
      return (
        <option
          key={
            p.country
              ? p.finalcode + ' - ' + p.country
              : p.foundationlabel
              ? p.finalcode + ' - ' + p.foundationlabel
              : this.props.survey_code.startsWith('E_') && !this.props.survey_code.includes('INTL')
              ? p.finalcode + ' - ' + p.postitle
              : p.finalcode
          }
          value={
            this.props.survey_code.startsWith('E_') && !this.props.survey_code.includes('INTL')
              ? p.finalcode + ' - ' + p.postitle + ' - ' + p.organizationalunit
              : p.finalcode
          }
        >
          {option}
        </option>
      );
    });
  }

  // renders the search box that filters down the list of positions
  render() {
    const { changed, country, cuts, search_term, pos_list } = this.props;

    const { disabled, modal } = this.state;

    const filtered_positions = this.filterPositions(
      search_term,
      pos_list,
      country
    );

    // console.log(cuts);

    return (
      <div className='results-position-list'>
        <select
          id='position-list'
          disabled={
            (pos_list[0].country || pos_list[0].foundationlabel) && !country
              ? true
              : false
          }
          className='form-control'
          onChange={(event) => this.onPositionSelect(event.target.value)}
        >
          {pos_list[0].country && !country ? (
            <option>Select Country First</option>
          ) : pos_list[0].foundationlabel && !country ? (
            <option>Select Foundation Type First</option>
          ) : (
            <option>Select Position... </option>
          )}
          {this.renderPositionsOptions(filtered_positions)}
        </select>
        <Button
          className='red-button'
          disabled={
            (pos_list[0].country || pos_list[0].foundationlabel) &&
            (!country || (changed && disabled))
              ? true
              : disabled
          }
          onClick={this.toggle}
        >
          View Position Cuts
        </Button>
        <Modal
          backdrop='static'
          size='lg'
          onClosed={this.handleClose}
          isOpen={modal}
          toggle={this.toggle}
          className='results-modal'
        >
          <ModalHeader toggle={this.toggle}>Select Data Cut(s)</ModalHeader>
          <ModalBody>
            <BasicQueryCuts />
            {Object.keys(_.pickBy(cuts, _.isNumber)).length !== 0 ? (
              <BasicQueryResults />
            ) : null}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    cuts: state.results.cuts,
    changed: state.results.changed,
    country: state.results.country,
    pos_list: state.results.pos_list,
    search_term: state.results.search_term,
    survey_id: state.results.survey_id,
    survey_code: state.results.survey_code,
  };
}

export default connect(mapStateToProps, {
  clearQuery,
  demoQuery,
  getQuery,
  posCount,
})(BasicQueryPostionList);
