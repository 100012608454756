import React from 'react';

const ParticipateSubmitted = () => {
  return (
    <div style={{ margin: '15px' }}>
      <p>Thank you for confirming your participation in the above survey(s)!</p>
      <p>You will receive an email confirmation shortly.</p>
      <p>
        If you need to make any changes, please contact The Croner Company at{' '}
        <a href='mailto:accountrelations@croner.com'>accountrelations@croner.com</a>.
      </p>
    </div>
  );
};

export default ParticipateSubmitted;
